.FormContactMe {
    position: relative;
    display: block;
    margin-bottom: 2rem;
    font-family: 'Open Sans';
    font-size: 1.8rem;
    font-weight: 400;
}

.FormContactMe .title {
    margin-bottom: 2rem;
    color: var(--color-title);
    font-family: 'Playfair Display';
    font-weight: 700;
    text-transform: capitalize;
}

.FormContactMe .label {
    position: relative;
    display: block;
    margin-bottom: .5rem;
    font-family: 'Playfair Display';
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: capitalize;
}

.FormContactMe input[type=text],
.FormContactMe input[type=email],
.FormContactMe textarea {
    position: relative;
    display: block;
    width: 100%;
    outline-color: var(--color-primary);
    padding: 1rem;
    color: var(--color-text-light-2);

    font-size: 1.6rem;
}

.FormContactMe input[type=text]:focus,
.FormContactMe input[type=email]:focus,
.FormContactMe textarea:focus {
    color: var(--color-text);
}

.FormContactMe .input {
    margin-bottom: 2.5rem;
}

.FormContactMe button {
    padding: 1.4rem 4rem;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: .1rem;
    cursor: pointer;

    background-color: var(--color-primary);
    border: none;
}

.FormContactMe button:hover {
    background-color: var(--color-primary-light);
}

.FormContactMe .label > span {
    color: var(--color-primary);
    font-weight: 700;
}

.FormContactMe .msg {
    font-size: 2.2rem;
    line-height: 1.1;
}