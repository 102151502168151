.footer {
    position: relative;
    display: block;
    background-color: var(--color-grey-dark-3);
}

.footer .wrapper {
    position: relative;
    display: flex;
    max-width: 110rem;
    padding: 3rem 0;
    margin: 0 auto;
    justify-content: space-between;
}

.footer .heading {
    margin-bottom: 1.2rem;
    color: #fff;
    font-family: var(--font-heading);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.footer .list {
    list-style: none;
    display: flex;
}

.footer .list-item {
    margin-right: .5rem;
}

.footer .list-link {
    color: #CC494F;
    text-decoration: none;
}

.footer .copyright {
    padding: .5rem 0;
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
}

@media only screen and (max-width: 37.5em) {
    .footer .wrapper {
        padding: 2rem;
        width: 85%;
        flex-direction: column;
        justify-content: stretch;
    }

    .footer .wrapper aside {
        margin-bottom: 2rem;
    }
}