@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-primary: #CC494F;
  --color-primary-light: #ca5a60;

  --color-text: #252525;
  --color-text-light-1: #666;
  --color-text-light-2: #999;
  --color-title: #353535;

  --font-primary: 'Open Sans', sans-serif;
  --font-heading: 'Playfair Display', sans-serif;

  --color-grey-dark-3: #212121;

  --max-width: 110rem;

  --bp-medium: 56.25em;
  --bp-small: 37.5em;    /* 600px */
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--font-primary);
  font-size: 1.6rem;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
 monospace; }

 h2 {
   font-size: 3.5rem;
   font-weight: 700;
 }

 h3 {
  color: #353535;
  color: var(--color-title);
  font-family: 'Playfair Display', sans-serif;
  font-family: var(--font-heading);
  font-size: 2.8rem;
  font-weight: 700;
 }

 p {
   font-size: 1.9rem;
   font-weight: 400;
   line-height: 3.4rem;
 }
.HeaderTitle_site-title-wrapper__3p43v {
    position: relative;
    height: 100%;

    display: flex;
    align-items: center;
}

.HeaderTitle_site-title__1-SyM {
    position: relative;
    display: inline-block;
}

.HeaderTitle_site-title__1-SyM .HeaderTitle_link__L3TVH {
    color: #fff;
    margin-right: 1.2rem;
    font-family: var(--font-heading);
    text-decoration: none;
    transition: all .3s ease;
}

.HeaderTitle_site-title__1-SyM .HeaderTitle_link__L3TVH:hover{
    color: var(--color-primary);
}

.HeaderTitle_site-description__MAVI- {
    padding: .5rem 1.5rem;
    color: #fff;
    border-left: 1px solid #fff;

    position: relative;
}
.NavigationItem_NavigationItem__1SMux {
    border-bottom: 5px solid transparent;
    padding-top: 5px;
    color: #fff;
    font-family: var(--font-heading);
    font-size: 1.6rem;
    font-weight: 600;

    display: flex;
    align-items: center;

    transition: all .1s;
}

.NavigationItem_NavigationItem__1SMux:not(:last-child) {
    margin-right: 2rem;
}

.NavigationItem_NavigationItem__1SMux:hover {
    color: rgba(255, 255, 255, .75);
    border-bottom: 5px solid var(--color-primary);
}

.NavigationItem_NavigationItem__1SMux .NavigationItem_Active__12tFh {
    color: var(--color-primary);
}

.NavigationItem_NavigationItem__1SMux a {
    color: currentColor;
    text-decoration: none;
    text-transform: uppercase;

    transition: color .2s;
}
.Navigation_Navigation__vl232 {
    position: relative;
    height: 100%;
}

.Navigation_NavigationItems__1bSyb {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;

    display: flex;
}
.Header_header__1VCKf {
    position: relative;
    display: block;
    height: 8rem;
    color: rgba(33, 33, 33, 0.50);
    background-color: var(--color-grey-dark-3);
    /* background-image: url(../../assets/images/hero-levitar.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 9999em;
    /* background-blend-mode: darken; */
    /* filter: brightness(80%); */
}

.Header_logo__3_SJs {
    position: relative;
    display: inline-block;
    transition: transform .2s;
}

.Header_logo__3_SJs:hover {
    transform: scale(1.05);
}

.Header_site-header-wrapper__36Qtc {
    height: 100%;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
}

.Header_site-header__2yEu9 {
    height: 100%;
    

    max-width: 110rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Icon_icon__7KZWD {
    height: 2.3rem;
    width: 3rem;
    fill: var(--color-primary);
}

.Icon_icon__7KZWD:hover {
    fill: var(--color-primary-light);
}
.FormMailList_form-mail-list__1F4qL {
    position: relative;
    display: block;
}

.FormMailList_heading__1SyKv {
    margin-bottom: 1.2rem;
    color: #fff;
    font-family: var(--font-heading);
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
}

.FormMailList_form-mail-list__1F4qL input[type=email] {
    position: relative;
    display: block;
    padding: 0 .6rem;
    min-height: 3.2rem;
    margin-bottom: 1rem;
    font-family: 'Open Sans';
    font-size: 1.5rem;
    border: .1rem solid #999;
    border-radius: .4rem;
}

.FormMailList_form-mail-list__1F4qL input[type=email]::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .45);
}

.FormMailList_form-mail-list__1F4qL input[type=email]:-ms-input-placeholder {
    color: rgba(0, 0, 0, .45);
}

.FormMailList_form-mail-list__1F4qL input[type=email]::-ms-input-placeholder {
    color: rgba(0, 0, 0, .45);
}

.FormMailList_form-mail-list__1F4qL input[type=email]::placeholder {
    color: rgba(0, 0, 0, .45);
}

.FormMailList_form-mail-list__1F4qL button {
    border: none;
    padding: 1rem 3rem;
    background-color: var(--color-primary);
    color: #fff;
    cursor: pointer;
    border-radius: .4rem;
    font-size: 1.5rem;
}

.FormMailList_form-mail-list__1F4qL button:hover {
    background-color: var(--color-primary-light);
}

@media only screen and (max-width: 37.5em) {
    .FormMailList_form-mail-list__1F4qL input[type=email],
    .FormMailList_form-mail-list__1F4qL button {
        width: 100%;
    }
}
.Footer_footer__1w0lV {
    position: relative;
    display: block;
    background-color: var(--color-grey-dark-3);
}

.Footer_footer__1w0lV .Footer_wrapper__1UzXs {
    position: relative;
    display: flex;
    max-width: 110rem;
    padding: 3rem 0;
    margin: 0 auto;
    justify-content: space-between;
}

.Footer_footer__1w0lV .Footer_heading__3sCUN {
    margin-bottom: 1.2rem;
    color: #fff;
    font-family: var(--font-heading);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.Footer_footer__1w0lV .Footer_list__1QJ4w {
    list-style: none;
    display: flex;
}

.Footer_footer__1w0lV .Footer_list-item__3JRmZ {
    margin-right: .5rem;
}

.Footer_footer__1w0lV .Footer_list-link__3I_zT {
    color: #CC494F;
    text-decoration: none;
}

.Footer_footer__1w0lV .Footer_copyright__2OWH4 {
    padding: .5rem 0;
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
}

@media only screen and (max-width: 37.5em) {
    .Footer_footer__1w0lV .Footer_wrapper__1UzXs {
        padding: 2rem;
        width: 85%;
        flex-direction: column;
        justify-content: stretch;
    }

    .Footer_footer__1w0lV .Footer_wrapper__1UzXs aside {
        margin-bottom: 2rem;
    }
}
.HeaderHero_hero__1yTaq {
    position: relative;
    display: block;
    padding: 5rem 0;
    color: #fff;
}

.HeaderHero_hero-inner__2KSJv {
    display: flex;
    flex-direction: column;
    max-width: 110rem;
    margin: 0 auto;
    padding: 0 1rem;
}

.HeaderHero_hero-title__3iiQF {
    max-width: 63%;
    font-size: 8.8rem;
    line-height: 1;

    margin-bottom: 2rem;
}

.HeaderHero_hero-text__3hhb- {
    max-width: 63%;
    margin-bottom: 3rem;
    font-size: 1.9rem;
    font-weight: 300;
    color: #fff;
    line-height: 3.4rem;
}
.CategoryPreview_category-preview__2LGiG {
    flex: 1 1;
    color: var(--color-text);
}

.CategoryPreview_category-preview__2LGiG:not(:last-child) {
    margin-right: 3.5rem;
}

.CategoryPreview_category-preview__2LGiG img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: .5rem;
}

.CategoryPreview_category-preview__2LGiG .CategoryPreview_title__5LpV3 {
    padding-bottom: 1rem;
}

.CategoryPreview_category-preview__2LGiG .CategoryPreview_link__1Hkye,
.CategoryPreview_category-preview__2LGiG .CategoryPreview_more__3jMUR {
    color: var(--color-primary);
    text-decoration: none;
}

.CategoryPreview_category-preview__2LGiG .CategoryPreview_link__1Hkye:hover,
.CategoryPreview_category-preview__2LGiG .CategoryPreview_more__3jMUR:hover {
    color: var(--color-primary-light);
}

.CategoryPreview_category-preview__2LGiG .CategoryPreview_link__1Hkye {
    text-transform: capitalize  ;
}

.CategoryPreview_category-preview__2LGiG p {
    padding-bottom: 1rem;
}

@media only screen and (max-width: 37.5em) {
    .CategoryPreview_category-preview__2LGiG {
        margin-bottom: 2rem;
    }
}
.Button_Button__3gFiX {    
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
}

.Button_Button__3gFiX a {
    padding: 1rem 3rem;
    background-color: var(--color-primary);
    color: currentColor;
    border-radius: .4rem;
    text-decoration: none;
    transition: all .3s;
}

.Button_Button__3gFiX a:hover {
    background-color: var(--color-primary-light);
}
.Home_area-content__1KJRH {
    position: relative;
    display: block;
}

.Home_row-content__3i2Xp {
    position: relative;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    margin: 0 auto 3rem auto;
    max-width: 110rem;
}

@media only screen and (max-width: 37.5em) {
    .Home_row-content__3i2Xp {
        flex-direction: column;
        width: 85%;
    }
}
.Blog_Blog__iVXs3 {
    max-width: var(--max-width);
    margin: 0 auto;
}
.AboutMe_AboutMe__xP-ln {
    color: var(--color-text);
    display: flex;
}

.AboutMe_Photo__XRDib {
    height: 40vh;
    border-radius: 1rem;
    box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .25);
    margin-right: 5rem;
}

.AboutMe_Content__2cyQb {
    color: currentColor;
    margin-top: 1.9rem;
    margin-bottom: 2.8rem;
    font-family: var(--font-text);
}

.AboutMe_Title__1UoaM {
    text-transform: capitalize;
}

.AboutMe_Text__2hghi {
    margin: 2rem 0;
}

@media only screen and (max-width: 37.5em) {
    .AboutMe_AboutMe__xP-ln {
        flex-direction: column;
    }

    .AboutMe_Photo__XRDib {
        margin-bottom: 2rem;
    }
}
.ContentText_content-text__GXsls {
    position: relative;
    display: block;
}

.ContentText_title__kg2qt {
    text-transform: capitalize;
}

.ContentText_text__1nz6N {
    margin: 2rem 0;
}
.AboutMe_container__fjg6V {
    padding: 2rem;
    display: block;
}

.AboutMe_container__fjg6V img {
    height: 40vh;
    border-radius: 1rem;
    box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .25);
}

.AboutMe_content-text__1FAkC {
    margin: 0 2rem;
}

.AboutMe_content-text__1FAkC p {
    margin-top: 1.9rem;
    margin-bottom: 2.8rem;
}

.AboutMe_container-content__AaZvR {
    max-width: 110rem;
    margin: 0 auto;
    display: flex;
    border-bottom: 1px solid #000;
}

.AboutMe_container-content__AaZvR:last-of-type {
    border-bottom: none;
}
.FormContactMe_FormContactMe__2E-8S {
    position: relative;
    display: block;
    margin-bottom: 2rem;
    font-family: 'Open Sans';
    font-size: 1.8rem;
    font-weight: 400;
}

.FormContactMe_FormContactMe__2E-8S .FormContactMe_title__1_VmI {
    margin-bottom: 2rem;
    color: var(--color-title);
    font-family: 'Playfair Display';
    font-weight: 700;
    text-transform: capitalize;
}

.FormContactMe_FormContactMe__2E-8S .FormContactMe_label__1pbka {
    position: relative;
    display: block;
    margin-bottom: .5rem;
    font-family: 'Playfair Display';
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: capitalize;
}

.FormContactMe_FormContactMe__2E-8S input[type=text],
.FormContactMe_FormContactMe__2E-8S input[type=email],
.FormContactMe_FormContactMe__2E-8S textarea {
    position: relative;
    display: block;
    width: 100%;
    outline-color: var(--color-primary);
    padding: 1rem;
    color: var(--color-text-light-2);

    font-size: 1.6rem;
}

.FormContactMe_FormContactMe__2E-8S input[type=text]:focus,
.FormContactMe_FormContactMe__2E-8S input[type=email]:focus,
.FormContactMe_FormContactMe__2E-8S textarea:focus {
    color: var(--color-text);
}

.FormContactMe_FormContactMe__2E-8S .FormContactMe_input__v4VCs {
    margin-bottom: 2.5rem;
}

.FormContactMe_FormContactMe__2E-8S button {
    padding: 1.4rem 4rem;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: .1rem;
    cursor: pointer;

    background-color: var(--color-primary);
    border: none;
}

.FormContactMe_FormContactMe__2E-8S button:hover {
    background-color: var(--color-primary-light);
}

.FormContactMe_FormContactMe__2E-8S .FormContactMe_label__1pbka > span {
    color: var(--color-primary);
    font-weight: 700;
}

.FormContactMe_FormContactMe__2E-8S .FormContactMe_msg__3w4pZ {
    font-size: 2.2rem;
    line-height: 1.1;
}
.ContactMe_ContactMe__32Oaz {
    max-width: 110rem;
    margin: 0 auto;
}

