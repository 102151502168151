.form-mail-list {
    position: relative;
    display: block;
}

.heading {
    margin-bottom: 1.2rem;
    color: #fff;
    font-family: var(--font-heading);
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
}

.form-mail-list input[type=email] {
    position: relative;
    display: block;
    padding: 0 .6rem;
    min-height: 3.2rem;
    margin-bottom: 1rem;
    font-family: 'Open Sans';
    font-size: 1.5rem;
    border: .1rem solid #999;
    border-radius: .4rem;
}

.form-mail-list input[type=email]::placeholder {
    color: rgba(0, 0, 0, .45);
}

.form-mail-list button {
    border: none;
    padding: 1rem 3rem;
    background-color: var(--color-primary);
    color: #fff;
    cursor: pointer;
    border-radius: .4rem;
    font-size: 1.5rem;
}

.form-mail-list button:hover {
    background-color: var(--color-primary-light);
}

@media only screen and (max-width: 37.5em) {
    .form-mail-list input[type=email],
    .form-mail-list button {
        width: 100%;
    }
}