.hero {
    position: relative;
    display: block;
    padding: 5rem 0;
    color: #fff;
}

.hero-inner {
    display: flex;
    flex-direction: column;
    max-width: 110rem;
    margin: 0 auto;
    padding: 0 1rem;
}

.hero-title {
    max-width: 63%;
    font-size: 8.8rem;
    line-height: 1;

    margin-bottom: 2rem;
}

.hero-text {
    max-width: 63%;
    margin-bottom: 3rem;
    font-size: 1.9rem;
    font-weight: 300;
    color: #fff;
    line-height: 3.4rem;
}