.container {
    padding: 2rem;
    display: block;
}

.container img {
    height: 40vh;
    border-radius: 1rem;
    box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .25);
}

.content-text {
    margin: 0 2rem;
}

.content-text p {
    margin-top: 1.9rem;
    margin-bottom: 2.8rem;
}

.container-content {
    max-width: 110rem;
    margin: 0 auto;
    display: flex;
    border-bottom: 1px solid #000;
}

.container-content:last-of-type {
    border-bottom: none;
}