.site-title-wrapper {
    position: relative;
    height: 100%;

    display: flex;
    align-items: center;
}

.site-title {
    position: relative;
    display: inline-block;
}

.site-title .link {
    color: #fff;
    margin-right: 1.2rem;
    font-family: var(--font-heading);
    text-decoration: none;
    transition: all .3s ease;
}

.site-title .link:hover{
    color: var(--color-primary);
}

.site-description {
    padding: .5rem 1.5rem;
    color: #fff;
    border-left: 1px solid #fff;

    position: relative;
}