.header {
    position: relative;
    display: block;
    height: 8rem;
    color: rgba(33, 33, 33, 0.50);
    background-color: var(--color-grey-dark-3);
    /* background-image: url(../../assets/images/hero-levitar.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 9999em;
    /* background-blend-mode: darken; */
    /* filter: brightness(80%); */
}

.logo {
    position: relative;
    display: inline-block;
    transition: transform .2s;
}

.logo:hover {
    transform: scale(1.05);
}

.site-header-wrapper {
    height: 100%;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
}

.site-header {
    height: 100%;
    

    max-width: 110rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}