.Button {    
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
}

.Button a {
    padding: 1rem 3rem;
    background-color: var(--color-primary);
    color: currentColor;
    border-radius: .4rem;
    text-decoration: none;
    transition: all .3s;
}

.Button a:hover {
    background-color: var(--color-primary-light);
}