.content-text {
    position: relative;
    display: block;
}

.title {
    text-transform: capitalize;
}

.text {
    margin: 2rem 0;
}