@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-primary: #CC494F;
  --color-primary-light: #ca5a60;

  --color-text: #252525;
  --color-text-light-1: #666;
  --color-text-light-2: #999;
  --color-title: #353535;

  --font-primary: 'Open Sans', sans-serif;
  --font-heading: 'Playfair Display', sans-serif;

  --color-grey-dark-3: #212121;

  --max-width: 110rem;

  --bp-medium: 56.25em;
  --bp-small: 37.5em;    /* 600px */
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: var(--font-primary);
  font-size: 1.6rem;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
 monospace; }

 h2 {
   font-size: 3.5rem;
   font-weight: 700;
 }

 h3 {
  color: var(--color-title);
  font-family: var(--font-heading);
  font-size: 2.8rem;
  font-weight: 700;
 }

 p {
   font-size: 1.9rem;
   font-weight: 400;
   line-height: 3.4rem;
 }