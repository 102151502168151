.NavigationItem {
    border-bottom: 5px solid transparent;
    padding-top: 5px;
    color: #fff;
    font-family: var(--font-heading);
    font-size: 1.6rem;
    font-weight: 600;

    display: flex;
    align-items: center;

    transition: all .1s;
}

.NavigationItem:not(:last-child) {
    margin-right: 2rem;
}

.NavigationItem:hover {
    color: rgba(255, 255, 255, .75);
    border-bottom: 5px solid var(--color-primary);
}

.NavigationItem .Active {
    color: var(--color-primary);
}

.NavigationItem a {
    color: currentColor;
    text-decoration: none;
    text-transform: uppercase;

    transition: color .2s;
}