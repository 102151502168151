.Navigation {
    position: relative;
    height: 100%;
}

.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;

    display: flex;
}