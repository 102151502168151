.category-preview {
    flex: 1;
    color: var(--color-text);
}

.category-preview:not(:last-child) {
    margin-right: 3.5rem;
}

.category-preview img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: .5rem;
}

.category-preview .title {
    padding-bottom: 1rem;
}

.category-preview .link,
.category-preview .more {
    color: var(--color-primary);
    text-decoration: none;
}

.category-preview .link:hover,
.category-preview .more:hover {
    color: var(--color-primary-light);
}

.category-preview .link {
    text-transform: capitalize  ;
}

.category-preview p {
    padding-bottom: 1rem;
}

@media only screen and (max-width: 37.5em) {
    .category-preview {
        margin-bottom: 2rem;
    }
}