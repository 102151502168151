.area-content {
    position: relative;
    display: block;
}

.row-content {
    position: relative;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    margin: 0 auto 3rem auto;
    max-width: 110rem;
}

@media only screen and (max-width: 37.5em) {
    .row-content {
        flex-direction: column;
        width: 85%;
    }
}